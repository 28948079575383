<template>
  <div
    class="pure-g root"
    :class="[brandExclusion]"
  >
    <AppRefreshIndicator ref="refresh" />
    <div class="head pure-u-1">
      <div
        class="impersonation"
        v-if="isImpersonated && accessToken"
        v-text="impersonatedText"
      />
      <compHeader
        class="header"
        :important-messages="importantMessages"
        @reload-translations="reloadTranslations"
        @change-customer="handleCustomerChange"
      />
      <SideButtons @click="openPopup" />
      <PopupBase
        class="contactform"
        :show="contactFormOverlay"
        @close-pop-up="contactFormOverlay = false"
      >
        <PopupContactForm
          @close="contactFormOverlay = false"
          v-if="contactFormOverlay"
          :form-data="formData"
          @clearFormData="clearFormData()"
        />
      </PopupBase>
      <PopupBrands
        :show="showBrandsPopup"
        :brands="brands"
        @close-pop-up="showBrandsPopup = false"
      />
      <div
        :class="{
          'head__spacing--xs': isReady && ((!this.$auth.loggedIn && !isProductPage) || (this.$auth.loggedIn && !hasCompanies && !isProductPage)),
          'head__spacing--s': ((!isReady && !isProductPage) || (isReady && this.$auth.loggedIn && !isProductPage && hasCompanies)),
          'head__spacing--m': isReady && ((!this.$auth.loggedIn && isProductPage) || (this.$auth.loggedIn && !hasCompanies && isProductPage)),
          'head__spacing--l': ((!isReady && isProductPage) || (isReady && this.$auth.loggedIn && isProductPage && hasCompanies))
        }"
      >
        <ImportantMessages
          v-if="importantMessages"
          :important-messages="importantMessages"
          class="head__notification__mobile"
        />
        <main class="pure-u-1">
          <div :style="{'margin-top': headerHeight }">
            <slot />
          </div>
          <div
            class="modal-container"
          />
        </main>
      </div>
    </div>
    <div
      v-if="showAddressDropDown"
      class="bottom__overlay"
    >
      <OverlayAddressSelector
        :addresses="addresses"
        :key="componentKey"
      />
    </div>
    <compFooter
      :class="{ addressMargin: showAddressDropDown }"
    />
    <div
      id="appversion"
      v-text="appVersion"
    />
  </div>
</template>

<script>
import Header from '~/components/header/Header'
import ImportantMessages from '~/components/header/ImportantMessages'
import Footer from '~/components/footer/Footer'
import {storeToRefs} from 'pinia'
import {IntlService, load} from '@progress/kendo-vue-intl'
import {supplemental, main} from '~/config/localedata.json'
import SideButtons from '~/components/home/SideButtons.vue'
import {SIDEBAR_ACTIONS, FORM_DEFAULT_DATA, POPUP, COOKIEBOT_ID} from '~/assets/js/constants'
import PopupBase from '~/components/popup/PopupBase'
import PopupContactForm from '~/components/popup/PopupContactForm'
import PopupBrands from '~/components/popup/PopupBrands'
import {getRegionForSalesArea} from '~/assets/js/utils/region_service'
import AppRefreshIndicator from '~/components/loading-indicators/app-refresh-indicator.vue'
import brandMixin from '~/mixins/brand'
import OverlayAddressSelector from '~/components/cart/OverlayAddressSelection.vue'
import {useCustomerService} from '@/composables/customer_service'
import {ADDRESS_TYPE} from '~/assets/js/constants'
import jwt_decode from 'jwt-decode'
import {useUserStore} from '@/store/user'
import {useCartStore} from '@/store/cart'
import {useImportantMessagesStore} from '@/store/important-messages'
import {useProductDataStore} from '@/store/product-data'
import {getMatchedComponents} from '~/assets/js/authentication'

export default {
  components: {
    'compFooter': Footer,
    'compHeader': Header,
    ImportantMessages,
    SideButtons,
    PopupBase,
    PopupContactForm,
    AppRefreshIndicator,
    PopupBrands,
    OverlayAddressSelector
  },
  mixins: [brandMixin],
  setup() {
    const cartStore = useCartStore()
    const {getAddresses} = useCustomerService()
    const {companies, loadedCustomerUuid, selectedCustomer, excludedBrands, accessToken, isInternalUser, companyNo, salesAreaName} = storeToRefs(useUserStore())
    const {messages} = storeToRefs(useImportantMessagesStore())
    return {
      getAddresses,
      cartStore,
      companies,
      currentCustomerUuid: loadedCustomerUuid,
      selectedCustomer,
      excludedBrands,
      accessToken,
      importantMessages: messages,
      isInternalUser,
      companyNo,
      salesAreaName
    }
  },
  mounted() {
    // only load script when cookiebot consent has been accepted
    window.addEventListener('CookiebotOnAccept', () => {
      if (window.Cookiebot.consent.statistics)
      {
        this.$gtm.enable(true)
        if (this.$auth.loggedIn) {
          this.$gtm.trackEvent({
            event: 'login',
            category: 'login',
            action: 'login',
            companyNo: this.companyNo,
            salesAreaName: this.salesAreaName,
            userType: this.isInternalUser ? 'internal' : 'external'
          })
        }
      }
    }, false)
    let externalScript = document.createElement('script')
    externalScript.setAttribute('src', 'https://consent.cookiebot.com/uc.js')
    externalScript.setAttribute('data-cbid', COOKIEBOT_ID)
    externalScript.setAttribute('data-culture', this.$i18n.locale)
    document.head.appendChild(externalScript)

    window.addEventListener('scroll', this.handleScroll)
    const bodyRect = document.body.getBoundingClientRect()
    this.isMobile = bodyRect.width < 768
    this.headerHeight = this.isMobile ? '0px' : this.headerHeight
  },
  beforeUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.handleScroll)
    }
    // const el = document.getElementById('surveySubmitBtn')
    // if (el) {
    //   el.removeEventListener('click', this.sendGTMEvent())
    // }
  },
  computed: {
    head() {
      const componentName = getMatchedComponents(this.$route)[0]?.name
      const translationKeyTitle = componentName && `${componentName}Title`
      const translationKeyDescription = componentName && `${componentName}Description`
      const translationFallbackDescription = 'fallbackDescription'
      const i18nHead = useLocaleHead({addSeoAttributes: true}).value
      let description = ''
      if (translationKeyDescription && this.$te('seo.' + translationKeyDescription)) {
        description = this.$tv(translationKeyDescription, 'seo')
      } else if (this.$te('seo.' + translationFallbackDescription)) {
        description = this.$tv(translationFallbackDescription, 'seo')
      }
      return {
        title: translationKeyTitle && this.$te('seo.' + translationKeyTitle) ? this.$tv(translationKeyTitle, 'seo') : this.$tv('title', 'seo'),
        categoryLanguage: this.$i18n.locale,
        htmlAttrs: {
          ...i18nHead.htmlAttrs
        },
        meta: [
          ...i18nHead.meta,
          {
            hid: 'description',
            name: 'description',
            content: description
          }
        ],
        link: [...i18nHead.link]
      }
    },
    isProductPage() {
      return this.$route.name?.includes('products-product')
    },
    isSearchResultPage() {
      return this.$route.name?.includes('searchResults')
    },
    isProductDataPage() {
      return this.$route.name?.includes('categories')
        || this.$route.name?.includes('first-level-category')
        || this.$route.name?.includes('second-level-category')
        || this.$route.name?.includes('third-level-category')
        || this.$route.name?.includes('products-product')
    },
    appVersion() {
      return (this.$config.public.environment || 'DEV') + '(' + this.$globalization.getRegion() + ') - ' + (this.$config.public.release || 'DEV')
    },
    hasCompanies() {
      if (this.companies) {
        return this.companies.length > 0
      }
      return false
    },
    customerRegion() {
      let salesAreaName = this.selectedCustomer?.salesOrg + '-' + this.selectedCustomer?.salesChannel + '-' + this.selectedCustomer?.distribution
      return this.selectedCustomer && getRegionForSalesArea(salesAreaName)
    },
    brandExclusion() {
      if (this.excludedBrands?.length > 0) {
        if (this.excludedBrands?.length === 1) {
          return ` nobrand-${this.excludedBrands.join('')}`
        }
        return ` nobrand-${this.excludedBrands.join('')} nobrand-${this.excludedBrands.join(' nobrand-')}`
      }
      return null
    },
    showAddressDropDown() {
      if (this.addresses) {
        return this.addresses?.length > 1 && (this.isSearchResultPage || this.isProductDataPage)
      }
      return false
    },
    isImpersonated() {
      if (this.accessToken) {
        const decodedToken = jwt_decode(this.accessToken)
        return decodedToken.extension_isimpersonated === '1'
      }
      return false
    },
    impersonatedText() {
      if (this.isImpersonated) {
        const decodedToken = jwt_decode(this.accessToken)
        return 'Impersonated: ' + decodedToken.email
      }
      return ''
    }
  },
  data() {
    const localizationProvider = {}
    Object.defineProperties(localizationProvider, {
      language: {
        enumerable: true,
        value: this.$i18n.locale
      },
      toLanguageString: {
        enumerable: true,
        value: (str, def) => {
          if (this.$te(`kendo.${str}`)) {
            return this.$tv(str, 'kendo')
          }

          return (this.$te(str) ? this.$tv(str) : def)}
      }
    })

    let intlProvider = new IntlService(this.$i18n.locale)
    let formData = FORM_DEFAULT_DATA.CONTACT_FORM
    return {
      navigationIsOpened: false,
      localizationProvider,
      intlProvider,
      contactFormOverlay: false,
      formData,
      isCustomerSet: true,
      isReady: false,
      showBrandsPopup: false,
      popupSizeSmall: POPUP.Size.small,
      brands: [],
      userInteracted: false,
      addresses: [],
      componentKey: 0,
      headerHeight: '220px',
      scrollPosition: 0,
      isScrolled: false,
      isMobile: false
    }
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY === 0
    },
    async reloadTranslations() {
      this.$eventbus.emit('loading', true)
      await this.$i18n.waitForPendingLocaleChange()
      await this.$nextTick()
      this.intlProvider.locale = this.$i18n.locale
      this.$eventbus.emit('loading', false)
    },
    openPopup(action) {
      if (action === SIDEBAR_ACTIONS.Contact) {
        this.contactFormOverlay = true
      }
      if (action === SIDEBAR_ACTIONS.Feedback) {
        const el = document.getElementsByClassName('btn-open-survey')
        if (el?.length > 0 && el[0] !== null) {
          el[0].click()
        }
        else {
          // eslint-disable-next-line no-console
          console.log('Mopinion form not found!')
        }
      }
    },
    clearFormData() {
      this.formData = FORM_DEFAULT_DATA.CONTACT_FORM
    },
    sendGTMEvent() {
      this.$gtm.trackEvent({
        event: 'submit',
        category: 'feedback',
        action: 'submit_feedback_form',
        value: null
      })
    },
    setEventListener() {
      const el = document.getElementById('surveySubmitBtn')
      if (el) {
        el.addEventListener('click', this.sendGTMEvent)
      }
    },
    handleCustomerChange() {
      this.isCustomerSet = !this.isCustomerSet
      !this.isCustomerSet ? this.$eventbus.emit('loading', true) : this.$eventbus.emit('loading', false)
    },
    loadLocaleData() {
      let localeMain = main[this.$i18n.locale]
      load({supplemental}, {main: {[this.$i18n.locale]: localeMain}})
    },
    async loadBrandsData() {
      await useProductDataStore().fetchAllBrands()
      this.brands = []
      let freudenberg = this.getBrandByCode('F', this.$i18n.locale)
      if (freudenberg !== undefined) {
        this.brands.push(freudenberg)
      }
      let dichtomatik = this.getBrandByCode('D', this.$i18n.locale)
      if (dichtomatik !== undefined) {
        this.brands.push(dichtomatik)
      }
    },
    async fetchAddresses() {
      let allAddresses = await this.getAddresses(this.currentCustomerUuid)
      if (allAddresses && allAddresses.length > 0) {
        allAddresses = allAddresses.map(a => ({...a, dropDownName: `${a.name}, ${a.street}, ${a.zip} ${a.city}${a.addressRegion !== null ? ` ${a.addressRegion},` : ','} ${this.$tv(a.countryShortCode, 'countries')}${a.erpReference ? (' (' + a.erpReference + ')') : ''}`}))
        this.addresses = allAddresses.filter(a => a.addressType === ADDRESS_TYPE.Delivery)
        if (!this.selectedAddresses || !this.selectedAddresses.delivery) {
          const firstDeliveryAddress = this.addresses.find(a => a.addressType === ADDRESS_TYPE.Delivery)
          if (firstDeliveryAddress) {
            this.setAddressDelivery(firstDeliveryAddress)
          }
        } else {
          if (this.selectedAddresses?.delivery && !this.addresses.some(a => a.addressUuid === this.selectedAddresses.delivery.addressUuid)) {
            let address = this.addresses.find(a => a.addressType === ADDRESS_TYPE.Delivery)
            if (address) {
              await this.setSelectedAddress({address: address, type: ADDRESS_TYPE.Delivery})
            }
          }
        }
      }
    },
    setAddressDelivery(address) {
      this.cartStore.setSelectedAddress({address: address, type: ADDRESS_TYPE.Delivery})
    },
    registerCookiebotScripts() {
      window.addEventListener('CookiebotOnDialogDisplay', function() {
        let cookieDialogBody = document.getElementById('CybotCookiebotDialogBodyContentText').querySelector('a')
        if (cookieDialogBody) {
          cookieDialogBody.href = '#'
          cookieDialogBody.addEventListener('click', function (e) {
            e.preventDefault()
            document.getElementById('CybotCookiebotDialogBodyButtonDecline').click()
          })
        }
      })
      window.addEventListener('CookiebotOnLoad', function() {
        let cookieDeclarationLink = document.querySelector('.CookieDeclaration > p a')
        if (cookieDeclarationLink) {
          let linkTextElement = document.createElement('span')
          linkTextElement.style.cursor = 'pointer'
          linkTextElement.innerHTML = cookieDeclarationLink.innerHTML
          cookieDeclarationLink.parentNode.replaceChild(linkTextElement, cookieDeclarationLink)
        }
      })
    }
  },
  provide() {
    return {kendoIntlService: this.intlProvider, kendoLocalizationService: this.localizationProvider}
  },
  errorCaptured: function(err, _, info) {
    if (this.$gtm) {
      this.$gtm.trackEvent({
        event: 'exception',
        value: `${(err && err.message) || '-'}, info: ${info}`
      })
    }
  },
  updated: function () {
    this.$nextTick(function () {
      this.isReady = true
    })
  },
  created: function() {
    this.loadLocaleData()
    this.loadBrandsData()
    this.$eventbus.on('showBrandsPopup', () => this.showBrandsPopup = true)
    this.$eventbus.on('hideBrandsPopup', () => this.showBrandsPopup = false)
    this.$eventbus.on('showContactPopup', () => this.contactFormOverlay = true)
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.loadLocaleData()
        this.loadBrandsData()
        if (this.$route?.matched?.length === 0) {
          let path = this.$route.path.split('/')
          if (path.length > 2 && path[2] !== this.$i18n.locale) {
            path[2] = this.$i18n.locale
            navigateTo(path.join('/'))
          }
        }
      }
    },
    currentCustomerUuid: {
      immediate: true,
      handler() {
        this.fetchAddresses()
        this.componentKey += 1
      }
    },
    showAddressDropDown: {
      immediate: true,
      handler(val) {
        this.cartStore.setAddressDropdownVisibility(val)
      }
    },
    head: {
      immediate: true,
      handler(val) {
        if (!this.$route?.name?.startsWith('categories-')) {
          useHead(val)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.noImportantMessages {
  padding-top: 5rem;
}

.head {
  display: flex;
  flex-direction: column;

  @include breakpoint-down($md) {
    &__spacing--xs {
      margin-top: 3.625rem;
    }

    &__spacing--s {
      margin-top: 5.75rem;
    }

    &__spacing--m {
      margin-top: 7.375rem;

      .product-page {
        .mobile__header {
          top: 3.7rem;
        }
      }
    }

    &__spacing--l {
      margin-top: 9.25rem;

      .product-page {
        .mobile__header {
          top: 5.5rem;
        }
      }
    }
  }

  &__notification {
    display: none;

    @include breakpoint-up($md) {
      display: block;
    }
  }

  &__notification__mobile {
    display: none;

    @include breakpoint-down($md) {
      display: block;
    }
  }
}

.head:has(.mobile-menu.open) {
  .sticky {
    display: none;
  }
}

body:has(.mobile-menu.open) {
  overflow: hidden;

  .bottom__overlay {
    display: none;
  }
}

.bottom__overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 300;
  height: 2.625rem;
  background-color: $color__text__search;
  padding-top: .25rem;
  padding-bottom: .125rem;

  @include breakpoint-down($md) {
    height: 3.75rem;
  }
}

.addressMargin {
  margin-bottom: 2.625rem;
}

#appversion {
  color: $color__text__search;
  font-size: .6rem;
  font-family: 'Courier New', Courier, monospace;
  position: fixed;
  bottom: 0;
  right: 0;
  pointer-events: none;
  background: none;
  letter-spacing: normal;
}

@include breakpoint-up($xl) {
  .contactform .popup__wrapper {
    margin: 1.5rem auto;
  }
}

.mopinion-survey-content {
  #surveyHead {
    margin-bottom: 0 !important;
  }

  .form-actions,
  .question {
    order: 2;
  }

  .textarea-group {
    flex-basis: 100%;
    order: 1;

    textarea {
      max-width: 37.5rem;
      width: 100% !important;
    }
  }

  .surveyWindowWrap .surveyWindow {
    max-width: 40rem !important;
    width: 100% !important;
  }

  #page1 {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
  }

  .btn-open-survey {
    display: none !important;
  }

  .mopinion-survey-output.survey-material-white {
    .form-actions {
      flex-basis: 100%;
      padding: 1.25rem;

      .btn-submit {
        background-color: $color--btn__primary;
        color: $color__white;
        border: .0625rem solid $color--btn__primary;
      }
    }
  }
}

.impersonation {
  background-color: $color__text--error;
  color: $color__white;
  text-align: center;
}
</style>
