<template>
  <div class="skeleton__search">
    <div
      v-if="mobile"
      class="skeleton__search__headline mobile"
    >
      <Skeleton class="skeleton__search__headline__text" />
    </div>
    <div
      v-else
      class="skeleton__search__headline"
    >
      <Skeleton class="skeleton__search__headline__text" />
      <NavigationClose
        class="close"
        @click="$emit('close', $event)"
      />
    </div>
    <div
      class="skeleton__search__content"
    >
      <div
        class="skeleton__search__content__row"
        v-for="row in dummyArray"
        :key="row.index"
      >
        <Skeleton
          class="skeleton__search__content__row__picture"
          :shape="'circle'"
        />
        <div class="skeleton__search__content__row__text">
          <Skeleton class="skeleton__search__content__row__text__long" />
          <Skeleton class="skeleton__search__content__row__text__middle" />
        </div>
        <Skeleton class="skeleton__search__content__row__sku" />
        <Skeleton class="skeleton__search__content__row__icon" />
      </div>
    </div>
  </div>
</template>
<script>
import {Skeleton} from '@progress/kendo-vue-indicators'
import NavigationClose from '~/assets/icons/navigation_close.svg?component'

export default {
  components: {
    Skeleton,
    NavigationClose
  },
  props: {
    mobile: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    let dummyArray = new Array(10).fill({index: 0}).map((e, i) => e.index = i)
    return {
      dummyArray: dummyArray
    }
  }
}
</script>
