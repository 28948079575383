<template>
  <div
    class="overlay"
    v-click-outside="closeOverlay"
  >
    <div
      v-if="searchTermResult.customerSuggestions?.length > 0"
      class="overlay__suggestions"
    >
      <div
        v-for="(suggestion, index) in searchTermResult.customerSuggestions.slice(0, 4)"
        :key="index"
      >
        <div
          class="suggestion"
          v-text="'#' + suggestion.CustomerSku"
          @click="openBuyBoxSku(suggestion.Sku)"
        />
      </div>
    </div>
    <div
      v-if="searchTermResult && searchTermResult.searchResults && searchTermResult.searchResults.length"
      class="overlay__result"
    >
      <div class="overlay__header">
        <div
          class="h5"
        >
          <span v-tv:searchResults="'AmountOfProducts'" />: <span
            v-text="searchTermResult?.count?.toLocaleString(this.$i18n.locale === 'en' ? 'en' : 'de')"
          />
        </div>
        <NavigationClose
          class="close"
          @click="closeOverlay"
        />
      </div>
      <NewSearchOverlayResults
        :products="mappedProducts"
        :l2categories="l2categories"
        :categories="categories"
        :search-term="searchTerm"
        :is-any-search="isAnySearch"
        :search-filter="searchFilter"
        :is-inch-search="isInchSearch"
        @close-overlay="closeOverlay"
        @openBuyBox="openBuyBox"
        @clickItem="clickItem"
      />
    </div>
    <div
      v-else-if="searchTermResult && searchTermResult.searchResults && searchTermResult.searchResults.length === 0 && searchTermResult.customerSuggestions?.length === 0"
      class="overlay__noProducts"
    >
      <div
        class="h5"
        v-tv:searchResults="'NoProducts'"
      />
    </div>
    <div
      v-else-if="searchTermResult && !searchTermResult.customerSuggestions?.length > 0"
      class="overlay__skeleton"
    >
      <SearchSkeleton
        @close="closeOverlay"
      />
    </div>
  </div>
</template>
<script>
import NewSearchOverlayResults from '~/components/search/NewSearchOverlayResults.vue'
import NavigationClose from '~/assets/icons/navigation_close.svg?component'
import SearchSkeleton from '~/components/skeletons/SearchSkeleton.vue'
import {getMappedProducts} from '~/assets/js/utils/product_attributes_mapping'

export default {
  components: {
    NavigationClose,
    NewSearchOverlayResults,
    SearchSkeleton
  },
  props: {
    searchTermResult: {
      type: Object,
      default () {
        return {}
      }
    },
    l2categories: {
      type: Array,
      default () {
        return []
      }
    },
    categories: {
      type: Array,
      default () {
        return []
      }
    },
    searchTerm: {
      type: String,
      default() {
        return ''
      }
    },
    searchFilter: {
      type: Array,
      default () {
        return []
      }
    },
    isInchSearch: {
      type: Boolean,
      default() {
        return false
      }
    },
    isAnySearch: {
      type: Boolean,
      default () {
        return false
      }
    },
    active: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data() {
    return {
      topResults: []
    }
  },
  methods: {
    closeOverlay(event) {
      const searchElement = document.body.getElementsByClassName('nav__main__searchbar').item(0)
      if (this.active && !searchElement.contains(event?.target)) {
        this.$emit('close-overlay')
      }
    },
    openBuyBox(product) {
      this.$emit('openBuyBox', product)
    },
    openBuyBoxSku(sku) {
      this.$emit('openBuyBoxSku', sku)
    },
    clickItem(sku, rank) {
      this.$emit('clickItem', sku, rank)
      this.$emit('close-overlay')
    }
  },
  computed: {
    mappedProducts() {
      return getMappedProducts(this.searchTermResult)
    }
  }
}
</script>

<style lang="scss">
.overlay {
  background-color: $color__content--background__secondary;
  padding: $default-margin-padding-width $default-margin-padding-2x $default-margin-padding-width $default-margin-padding-2x;
  box-shadow: 0 .625rem 1.25rem .3125rem $color--nav__main--background-33pct;
  border: .125rem solid $color--input--focus;
  max-height: calc(100vh - 13rem);
  overflow-y: auto;

  @include breakpoint-up($lg) {
    width: 64rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    .close.icon--svg {
      position: relative;
      top: -.5rem;
      font-size: 1.5rem;
      cursor: pointer;
    }
  }

  .h5 {
    margin-bottom: $default-margin-padding-width;
  }

  &__skeleton {
    width: 100%;
  }
}
</style>
